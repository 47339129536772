import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/services/base-data.service';
import { Store } from 'src/app/home/reports/models/reports.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  startDate: any;
  endDate: any;
  requestDateRange: string;

  private apiLooksup = 'api/looksup/';
  stores: Store[];

  constructor(
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private baseDataService: BaseDataService
  ) { }


  setDate(start: any, end: any) {
    let startDate = this.datePipe.transform(start, 'yyyy/MM/dd');
    let endDate = this.datePipe.transform(end, 'yyyy/MM/dd');
    let requestDateRange = startDate + '-' + endDate;
    return requestDateRange;
  }


  getDateFormated(start: any,) {
    let startDate = this.datePipe.transform(start, 'yyyy/MM/dd HH:mm:ss');
    return startDate;
  }

  getDateFormatedAmpm(start: any,) {
    return moment(start).format("YYYY-MM-DD hh:mm A");
  }


  setDateRequest(reqType: string) {
    switch (reqType) {
      case 'Today':
        this.startDate = new Date();
        this.endDate = new Date();
        this.requestDateRange = this.setDate(this.startDate, this.endDate);
        break;

      case 'Week':
        this.startDate= moment().subtract( 1,'weeks').toDate();
        this.endDate= moment().subtract(1,'days').toDate();
        this.requestDateRange = this.setDate(this.startDate, this.endDate);
        break;

        case 'Month':

          this.endDate= moment().subtract(1,'days').toDate();
          this.startDate= moment().subtract(30,'days').toDate();
 
          this.requestDateRange = this.setDate(this.startDate, this.endDate);
        break;
    }
    return this.requestDateRange;
  }


  showSuccess(alertMassage: string) {
    this.toastr.success(alertMassage, 'Success!', {
      timeOut: 3000
    });
  }


  showError(alertMassage: string) {
    this.toastr.error(alertMassage, 'Error!', {
      timeOut: 3000
    });
  }


  getAbsoluteValue(num: number) {
    return Math.abs(num);
  }


  setSameHeight(cls) {
    var elements = document.getElementsByClassName(cls);
    // console.log(elements);

    equal_cols('.' + cls);
    function equal_cols(el) {
      // alert('.')
      var h = 0;
      $(el).each(function () {
        $(this).css({ 'height': 'auto' });

        if ($(this).outerHeight() > h) {
          h = $(this).outerHeight();
        }
      });
      $(el).each(function () {
        $(this).css({ 'height': h });
      });
    }


  }


  fixSingledate(date: string) {
    var retdate = date;
    var spt = date.split('-');
    if (spt[1] == 'null') {
      retdate = spt[0] + '-' + spt[0];
      // spt;
    }

    return retdate;
  }


  getUserDetails() {
    return {
      username: JSON.parse(localStorage.getItem('firstname')),
      isChainUser: JSON.parse(localStorage.getItem('isChainUser')),
      chainName: JSON.parse(localStorage.getItem('chainName')),
      storeName: JSON.parse(localStorage.getItem('storeName')),
      storeId: JSON.parse(localStorage.getItem('storeId')),
      chainId: JSON.parse(localStorage.getItem('chainId')),
      hasmultipleStores:JSON.parse(localStorage.getItem('hasmultipleStores')),
      chainIdList:JSON.parse(localStorage.getItem('chainIdList')),
      storeIdList:JSON.parse(localStorage.getItem('storeIdList'))
    }
  }


  getStoreListLooksup(): Observable<Store[]> {
    return this.baseDataService.makeGetCall(`${this.apiLooksup}${'/getStoreList'}`)
  }


  getThreeDecimalPlaces(number: number, place: number = 3) {
    let num = number?.toFixed(place);
    return num;
  }

  getFullNumber(value: number) {
    return parseInt('' + Math.round(value));
  }


}
