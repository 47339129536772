import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AuthResponse} from '../_models/signin.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: string = `${environment.baseUrl}`;

  private currentUserSubject: BehaviorSubject<AuthResponse>;
  public currentUser: Observable<AuthResponse>;

  grant_type: string = 'password';
  Authorization = '';
  app = 'Merchant';
  t = 'VENDOR';
  v = '1.5.1.4'

  constructor(
    private http: HttpClient,
    private router: Router
    ) {
      this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
    }


  public get currentUserValue(): AuthResponse {
    return this.currentUserSubject.value;
  }


  login(username: any, password: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new URLSearchParams();

    body.set('grant_type', this.grant_type );
    body.set('Authorization', this.Authorization);
    body.set('app', this.app);
    body.set('t', this.t);
    body.set('v', this.v);
    body.set('username', username);
    body.set('password', password);

    return this.http.post<AuthResponse>(this.baseUrl+'token',  body.toString(), {
        headers: myheader}).pipe(map((user: AuthResponse) => {
          this.storeDetails(user)
          this.currentUserSubject.next(user);
          return user;
    }))
  }


  storeDetails(user: any){
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('token', JSON.stringify(user.access_token));
    localStorage.setItem('userId', JSON.stringify(user.userId));
    localStorage.setItem('username', JSON.stringify(user.userName));
    localStorage.setItem('isChainUser', JSON.stringify(user.isChainUser));
    localStorage.setItem('chainName', JSON.stringify(user.chainName));
    localStorage.setItem('storeName', JSON.stringify(user.storeName));
    localStorage.setItem('firstname', JSON.stringify(user.firstName));
    localStorage.setItem('email', JSON.stringify(user.email));
    localStorage.setItem('storeId', JSON.stringify(user.storeId));
    localStorage.setItem('chainId', JSON.stringify(user.chainId));
    localStorage.setItem('sectorCode', JSON.stringify(user.sectorCode));
    localStorage.setItem('hasmultipleStores', JSON.stringify(user.hasmultipleStores));
    localStorage.setItem('chainIdList',(user.chainIdList));
    localStorage.setItem('storeIdList',(user.storeIdList));
  }


  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userId');
    // localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('isChainUser');
    localStorage.removeItem('chainName');
    localStorage.removeItem('storeName');
    localStorage.removeItem('firstname');
    localStorage.removeItem('email');
    localStorage.removeItem('storeId');
    localStorage.removeItem('sectorCode');
    localStorage.removeItem('chainId');
    // if (localStorage.has('storeId')) {

    // }
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
  }

}
